<template>
  <div class="pd20x flex1">
    <div class="contentBox h100pct ofA">
      <div class="filter" style="padding:10px 20px">
        <!-- <div>
          <a-button type="primary" @click="openClasses">开课</a-button>
        </div> -->
        <div>
          <label>搜索：</label>
          <a-select v-model="selectVal" style="width: 120px" >
            <a-select-option :value="item.val" v-for="(item,index) in option" :key="index">
              {{item.name}}
            </a-select-option>
          </a-select>
          <a-input-search placeholder="请输入搜索内容" v-model="searchInput" style="width: 300px" @search="onSearch" class="mr10px"/>
        </div>
      </div>
      <div class="order-table">
        <div class="tableHeader">
          <div style="width:120px">用户</div>
          <div style="flex:1">卷子标题</div>
          <div style="width:150px">正确题数/总题数</div>
          <div style="width:120px">得分/总分</div>
          <div style="width:90px">时间</div>
          <div style="width:120px">状态</div>
        </div>
        <template v-for="item in data">
          <div class="order-table-body" :key="item.id">
            <div class="orderHeader">
              <div>
                <span class="mr20px">【{{ item.sub_title }}】</span>
                <span>创建时间：{{item.created_at}}</span>
              </div>
              <div></div>
            </div>
            <div class="order-table-content">
              <div style="width:120px">{{item.nickname}}<br>{{item.username}}</div>
              <div style="flex:1" class="vertical">
                <div class="inline">
                  {{item.title}}
                </div>
              </div>
              <div style="width:150px">{{item.answer_right+'/'+item.total_number}}</div>
              <div style="width:120px">{{item.score2+'/'+item.score}}</div>
              <div style="width:90px">{{parseFloat(item.time/60).toFixed(2)}}分钟</div>
              <div style="width:120px">{{item.status==1?'已完成':'测试中'}}</div>
            </div>
          </div>
        </template>
      </div>
      <a-pagination :page-size="15" :show-total="total => `共 ${total} 条`" v-model="pagination.page" :total="total" @change="changePage"/>
    </div>
  </div>
</template>
<script>
import {abrsmRemark} from "@/libs/examapi";

const columns = [

];
import { formatDay } from '@/libs/moment'
import * as http from '@/libs/examapi'
import { routerlink } from '@/mixins/routerlink'
export default {
  name: 'purchase',
  mixins: [routerlink],
  data(){
    return{
      options: [],
      columns,
      searchInput: '',
      selectVal: 'mobile',
      option: [
        { name: '电话', val: 'mobile'},
        { name: '昵称', val: 'nickname'}
      ],
      filter:{
      },
      pagination:{
        page: 1,
        size: '15',
        pageSize: 15
      },
      total:0,
      allSubject:[],
      data: [],
      areaval: '',
      //填写快递
      expressShow: false,
      confirmLoading: false,
      express_no:null,
      currentItem: {}
    }
  },
  mounted(){
    this.getData()
  },
  methods:{
    formatDay,
    getData(){
      http.userPapersList({...this.pagination, ...this.filter})
          .then(res => {
            let { data, total } = res
            this.total = total
            this.data = data
          }).catch(error=>{
        console.log(error)
      })
    },
    onSearch(){
      this.filter = {}
      this.filter[this.selectVal] = this.searchInput
      this.getData()
    },
    changePage(current){
      this.pagination.page = current
      this.getData()
    },
    copyAddress(item, e){
      e.stopPropagation()
      let address = `${item.real_name}  ${item.phone}  ${item.province}${item.city}${item.detail}`
      this.$copyText(address).then(res=> {
        this.$message.success('复制成功')
      },error=>{
        this.$message.success('复制失败')
      })
    },
  }
}
</script>
<style lang="less" scoped>
.imgWrap{
  width: 120px;
  height: 70px;
  overflow: hidden;
  border-radius: 4px;
  img{
    width: 100%;
  }
}
.tableHeader{
  width: 100%;
  background: #eeeeee;
  display: flex;
  padding: 10px 0;
}
.orderHeader{
  background: #eeeeee;
  display: flex;
  padding: 10px 20px;
}
.order-table{
  min-width: 900px;
  padding: 10px;
  .order-table-body{
    margin-top: 10px;
  }
  .order-table-content{
    border: 1px solid #e6e8f0;
    border-top: none;
    border-right: none;
    display: flex;
    >div{
      min-width: 20px;
      border-right: 1px solid #e6e8f0;
      padding: 5px 10px;
      display: flex;
      align-items: center;
    }
    .vertical{
      flex-direction: column;
      justify-content: center;
      padding: 0;
      >.inline{
        display: block;
        width: 100%;
        border-bottom: 1px solid #e6e8f0;
        padding: 5px 10px;
        &:last-child{
          border-bottom: 0;
        }
      }
    }
  }

}
.copy{
  color: royalblue;
  cursor: pointer;
}

</style>
